import { jwtDecode } from 'jwt-decode';
import { bind } from 'lodash-decorators';
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction
} from 'mobx';
import { component } from 'tsdi';
import { Api as CorporateApi } from './api/CorporateClient';
import { Api as StudioApi } from './api/StudioClient';
import type { ApiConfig } from './api/TenantClient';

// eslint-disable-next-line no-duplicate-imports
import { Api as TenantApi } from './api/TenantClient';

import { Api as AnalyticsApi } from './api/AnalyticsClient';
import { Api as FitnessActivityApi } from './api/FitnessActivityClient';
import { Api as MobileApi } from './api/MobileClient';
import { Api as WalletApi } from './api/WalletClient';
import { autodisposer } from './reactions';

export type Permission = 'LOGGED_IN' | 'IS_DEV';
type UrlEnv = 'dev' | 'pre' | 'prod';
const CREDENTIAL_KEY = 'CREDENTIAL';

interface GoogleUserAuth {
    accessToken: string;
    email: string;
    firstName: string;
    lastName: string;
}

@component
export class Api {
    public permissions: Permission[] = [];

    constructor() {
        makeObservable(this, {
            permissions: observable,
            googleAuthChecked: observable,
            loggedIn: computed,
            addPermission: action,
            generateGoogleUserAuth: action.bound,
            cleanUserAuth: action.bound,
            googleUserAuth: observable,
            credential: observable,
            clientsInitialized: observable
        });
    }

    public clientsInitialized = false;

    public googleUserAuth: GoogleUserAuth | undefined = undefined;

    public credential: string | undefined;

    public get loggedIn() {
        return this.permissions.includes('LOGGED_IN');
    }

    public addPermission = (permission: Permission) => {
        if (this.permissions.includes(permission)) {
            return null;
        }
        return (this.permissions = [...this.permissions, permission]);
    };

    public removePermission = (permission: Permission) => {
        this.permissions = [
            ...this.permissions.filter((item) => item !== permission)
        ];
    };

    public hasPermission(permissions: Permission[] | Permission) {
        if (typeof permissions === 'string') {
            return this.permissions.includes(permissions);
        }
        return this.permissions.some((p) => permissions.includes(p));
    }

    @bind
    public gatewayApiUrl(service: string) {
        if (this.env === 'dev') {
            return `https://admin-gateway.api.dev.mysports-rewards.com/${service}`;
        } else if (this.env === 'pre') {
            return `https://admin-gateway.api.pre.mysports-rewards.com/${service}`;
        }
        return `https://admin-gateway.api.mysports-rewards.com/${service}`;
    }

    @bind
    public get env(): UrlEnv {
        const { hostname } = location;
        const matches = hostname.match(
            /(dev|pre|local)?\.?mysports-rewards\.com/
        );

        if (hostname.match(/localhost/)) {
            return 'dev';
        }

        if (matches) {
            const env = matches[1];

            if (env === 'local') {
                return 'dev';
            }

            if (env) {
                return env as UrlEnv;
            }
        }

        return 'prod';
    }

    public studioClient = this.generateStudioClient(
        this.generateApiConfig(this.gatewayApiUrl('studio-api'))
    );

    private generateStudioClient(apiConfig: ApiConfig<unknown>) {
        return new StudioApi(apiConfig);
    }

    public corporateClient = this.generateCorporateClient(
        this.generateApiConfig(this.gatewayApiUrl('corporate-api'))
    );

    public generateCorporateClient(apiConfig: ApiConfig<unknown>) {
        return new CorporateApi(apiConfig);
    }

    public tenantClient = this.generateTenantClient(
        this.generateApiConfig(this.gatewayApiUrl('corporate-tenant-api'))
    );

    private generateTenantClient(apiConfig: ApiConfig<unknown>) {
        return new TenantApi(apiConfig);
    }
    public mobileClient = this.generateMobileClient(
        this.generateApiConfig(this.gatewayApiUrl('mobile-api'))
    );

    private generateMobileClient(apiConfig: ApiConfig<unknown>) {
        return new MobileApi(apiConfig);
    }

    public fitnessActivityClient = this.generateFitnessActivityClient(
        this.generateApiConfig(this.gatewayApiUrl('fitness-acitivty-api'))
    );

    private generateFitnessActivityClient(apiConfig: ApiConfig<unknown>) {
        return new FitnessActivityApi(apiConfig);
    }

    public analyticsClient = this.generateAnalyticsClient(
        this.generateApiConfig(this.gatewayApiUrl('analytics-api'))
    );

    private generateAnalyticsClient(apiConfig: ApiConfig<unknown>) {
        return new AnalyticsApi(apiConfig);
    }
    public walletClient = this.generateWalletClient(
        this.generateApiConfig(this.gatewayApiUrl('wallet-api'))
    );

    private generateWalletClient(apiConfig: ApiConfig<unknown>) {
        return new WalletApi(apiConfig);
    }

    @bind
    public generateApiConfig(apiUrl: string, tenant?: string): ApiConfig {
        return {
            baseUrl: apiUrl,
            baseApiParams: {
                headers: {
                    authorization: `Bearer ${this.googleUserAuth?.accessToken}`,
                    ...(tenant ? { 'x-tenant-id': tenant } : {})
                },
                format: 'json'
            },
            customFetch: (input, init) =>
                fetch(input, init).then((response) => {
                    if (response.ok) {
                        return response;
                    }
                    if (response.status === 401) {
                        this.signOut();
                        this.removePermission('LOGGED_IN');
                    }
                    return response;
                })
        };
    }

    private updateClients() {
        this.corporateClient = this.generateCorporateClient(
            this.generateApiConfig(this.gatewayApiUrl('corporate-api'))
        );

        this.tenantClient = this.generateTenantClient(
            this.generateApiConfig(this.gatewayApiUrl('corporate-tenant-api'))
        );

        this.studioClient = this.generateStudioClient(
            this.generateApiConfig(this.gatewayApiUrl('studio-api'))
        );

        this.analyticsClient = this.generateAnalyticsClient(
            this.generateApiConfig(this.gatewayApiUrl('analytics-api'))
        );

        this.fitnessActivityClient = this.generateFitnessActivityClient(
            this.generateApiConfig(this.gatewayApiUrl('fitness-activity-api'))
        );

        this.walletClient = this.generateWalletClient(
            this.generateApiConfig(this.gatewayApiUrl('wallet-api'))
        );
        this.mobileClient = this.generateMobileClient(
            this.generateApiConfig(this.gatewayApiUrl('mobile-api'))
        );

        runInAction(() => {
            this.clientsInitialized = true;
        });
    }

    public checkForPermissions = async (): Promise<undefined | boolean> => {
        const credential = sessionStorage.getItem(CREDENTIAL_KEY);
        if (credential) {
            this.generateGoogleUserAuth(credential);
            runInAction(() => {
                this.googleAuthChecked = true;
            });
            this.addPermission('LOGGED_IN');
            return true;
        }

        runInAction(() => {
            this.googleAuthChecked = true;
        });
        return undefined;
    };

    private signOut() {
        sessionStorage.removeItem(CREDENTIAL_KEY);
    }

    public generateGoogleUserAuth(credential: string) {
        sessionStorage.setItem(CREDENTIAL_KEY, credential);
        const user = jwtDecode<DataCredential>(credential);

        this.googleUserAuth = {
            accessToken: credential,
            email: user.email,
            firstName: user.given_name,
            lastName: user.family_name
        };
    }

    public cleanUserAuth() {
        this.googleUserAuth = undefined;
    }

    public googleAuthChecked = false;

    public googleClientId =
        '743455673160-0cjthmfiuplfutokq74vho1ic7b1la8v.apps.googleusercontent.com';

    @autodisposer.tsdi
    public initialize() {
        return [
            reaction(
                () => this.googleUserAuth?.accessToken,
                async () => {
                    this.updateClients();
                }
            )
        ];
    }
}
