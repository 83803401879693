import AppsIcon from '@mui/icons-material/Apps';
import BarChartIcon from '@mui/icons-material/BarChart';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalActivityIcon from '@mui/icons-material/LocalActivityOutlined';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { bind } from 'lodash-decorators';
import { computed, makeObservable, observable } from 'mobx';
import React from 'react';
import { PartialRouteObject } from 'react-router';
import { component } from 'tsdi';
import FitnessServicesDeprecated from '../deprecated/fitness-services';
import { WorkoutRulesDeprecated } from '../deprecated/workout-rules';
import { FacilityCreate } from '../facility/create';
import { FacilityEdit } from '../facility/edit';
import FacilityDetailsForm from '../facility/edit/facility-details-form';
import FacilityLocation from '../facility/edit/facility-location';
import { Facility } from '../facility/index';
import { FacilityList } from '../facility/list';
import FitnessServices from '../fitness-services';
import { Login } from '../login';
import { NoMatch } from '../no-match/index';
import Reports from '../reports';
import Tenant from '../tenant';
import { TenantOnboarding } from '../tenant-onboarding';
import TenantContract from '../tenant/contract';
import TenantDetail from '../tenant/detail';
import TenantEmployee from '../tenant/employee';
import EmployeeActivities from '../tenant/employee/activities';
import EmployeeActivityPoints from '../tenant/employee/activity-points';
import EmployeeDetail from '../tenant/employee/detail';
import EmployeeFacilities from '../tenant/employee/facilities';
import EmployeeFitnessActivities from '../tenant/employee/fitness-activities';
import TenantEmployees from '../tenant/employees';
import TenantFeatures from '../tenant/features';
import TenantUsers from '../tenant/users';
import { TenantList } from '../tenants';
import Users from '../users';
import User from '../users/user';
import UserActivityPoints from '../users/user/activity-points';
import UserDetails from '../users/user/detail';
import UserFitnessActivities from '../users/user/fitness-activities';
import UserWallet from '../users/user/wallet';
import { WorkoutRules } from '../workout-rules';
import { Api, Permission } from './api';
import { injectTSDI } from './tsdi';
import VendorList from '../vouchers/vendor-list';
import { VendorDetails } from '../vouchers/vendor-details';
import { VoucherVendors } from '../vouchers';

export interface Route extends PartialRouteObject {
    permissions?: Permission | Permission[];
    showInNav?: boolean | 'exact';
    element: React.ReactNode;

    // element: () => JSX.Element;

    // Icon?(props: SvgIconProps): JSX.Element;
    Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };

    children?: Route[];
    title?: string;
    path: string;
}

@component
export class Routes {
    public routes: Route[] = [];

    private get api() {
        return injectTSDI(Api);
    }

    @bind
    public getPageTitle(path: string): string | undefined {
        const name = path.match('/[a-zA-Z-]*');
        if (name) {
            const current = this.routes?.find(
                (route) => route.path === name[0]
            );
            return current && current.title;
        }
        return undefined;
    }

    constructor() {
        makeObservable(this, {
            routes: observable,
            activeRoutes: computed,
            navLinks: computed
        });

        this.routes = [
            {
                path: '/users',
                element: <Users />,
                Icon: PeopleAltIcon,
                showInNav: true,
                permissions: ['LOGGED_IN'],
                title: 'Users'
            },
            {
                path: '/users/:userId',
                element: <User />,
                showInNav: false,
                permissions: ['LOGGED_IN'],
                title: 'User Details',
                children: [
                    {
                        path: '/',
                        element: <UserDetails />,
                        title: 'User Details'
                    },
                    {
                        path: '/details',
                        element: <UserDetails />,
                        title: 'User Details'
                    },
                    {
                        path: '/wallet',
                        element: <UserWallet />,
                        title: 'Wallet'
                    },
                    {
                        path: '/fitness-activities',
                        element: <UserFitnessActivities />,
                        title: 'Employee Activities'
                    },

                    {
                        path: '/activity-points',
                        element: <UserActivityPoints />,
                        title: 'Activity Points'
                    }
                ]
            },
            {
                path: '/',
                element: <Facility />,
                title: 'Dashboard',
                permissions: ['LOGGED_IN'],
                children: [
                    {
                        path: '/',
                        element: <TenantList />,
                        title: 'List'
                    }
                ]
            },
            {
                path: '/login',
                element: <Login />,
                title: 'Login'
            },
            {
                path: '/tenants',
                element: <TenantList />,
                Icon: AppsIcon,
                title: 'Tenants (MS Teams)',
                permissions: ['LOGGED_IN'],
                showInNav: true
            },
            {
                path: '/tenants-corporate',
                element: <TenantList />,
                Icon: AppsIcon,
                title: 'Tenants (MS Corporate)',
                permissions: ['LOGGED_IN'],
                showInNav: true
            },
            {
                path: '/tenant/:tenantName',
                element: <Tenant />,
                title: 'Tenant Detail',
                showInNav: false,
                permissions: ['LOGGED_IN'],
                children: [
                    {
                        path: '/',
                        element: <TenantDetail />,
                        title: 'Details'
                    },
                    {
                        path: '/details',
                        element: <TenantDetail />,
                        title: 'Detail'
                    },
                    {
                        path: '/contract',
                        element: <TenantContract />,
                        title: 'Features'
                    },
                    {
                        path: '/features',
                        element: <TenantFeatures />,
                        title: 'Features'
                    },
                    {
                        path: '/employees',
                        element: <TenantEmployees />,
                        title: 'Employees'
                    },
                    {
                        path: '/employees/:employeeId',
                        element: <TenantEmployee />,
                        title: 'Features',
                        children: [
                            {
                                path: '/',
                                element: <EmployeeDetail />,
                                title: 'Employee Details'
                            },
                            {
                                path: '/details',
                                element: <EmployeeDetail />,
                                title: 'Employee Details'
                            },

                            {
                                path: '/activities',
                                element: <EmployeeActivities />,
                                title: 'Employee Activities'
                            },
                            {
                                path: '/facilities',
                                element: <EmployeeFacilities />,
                                title: 'Employee Facilities'
                            },
                            {
                                path: '/fitness-activities',
                                element: <EmployeeFitnessActivities />,
                                title: 'Employee Fitness Activities'
                            },
                            {
                                path: '/activity-points',
                                element: <EmployeeActivityPoints />,
                                title: 'Employee Activity Points'
                            }
                        ]
                    },
                    {
                        path: '/users',
                        element: <TenantUsers />,
                        title: 'Users'
                    }
                ]
            },
            {
                path: '/tenant-onboarding',
                element: <TenantOnboarding />,
                title: 'Tenant Teams Onboarding',
                permissions: ['LOGGED_IN']
            },
            {
                path: '/facility',
                element: <Facility />,
                Icon: GpsFixedIcon,
                title: 'Facilities',
                showInNav: true,
                permissions: ['LOGGED_IN'],
                children: [
                    {
                        path: '/',
                        element: <FacilityList />,
                        title: 'Facility List'
                    },
                    {
                        path: '/create',
                        element: <FacilityCreate />,
                        title: 'Facility Create'
                    },
                    {
                        path: '/:facilityId',
                        element: <FacilityEdit />,
                        title: 'Facility Edit',
                        children: [
                            {
                                path: '/',
                                element: <FacilityDetailsForm />,
                                title: 'Facility Details form'
                            },
                            {
                                path: '/details',
                                element: <FacilityDetailsForm />,
                                title: 'Facility Details form'
                            },
                            {
                                path: '/location',
                                element: <FacilityLocation />,
                                title: 'Facility Location Edit'
                            }
                        ]
                    }
                ]
            },
            {
                path: '/workout-rules',
                element: <WorkoutRules />,
                Icon: FitnessCenterIcon,
                title: 'Workout Rules',
                permissions: ['LOGGED_IN'],
                showInNav: true
            },
            {
                path: '/deprecated/workout-rules',
                element: <WorkoutRulesDeprecated />,
                Icon: FitnessCenterIcon,
                title: 'Workout Rules (Deprecated)',
                permissions: ['LOGGED_IN'],
                showInNav: true
            },
            {
                path: '/fitness-services',
                element: <FitnessServices />,
                Icon: MiscellaneousServicesIcon,
                title: 'Fitness Services',
                permissions: ['LOGGED_IN'],
                showInNav: true
            },
            {
                path: '/deprecated/fitness-services',
                element: <FitnessServicesDeprecated />,
                Icon: MiscellaneousServicesIcon,
                title: 'Fitness Services (Deprecated)',
                permissions: ['LOGGED_IN'],
                showInNav: true
            },
            {
                path: '/reports',
                element: <Reports />,
                Icon: BarChartIcon,
                showInNav: true,
                title: 'Reports'
            },

            {
                path: '/vendors',
                element: <VoucherVendors />,
                Icon: LocalActivityIcon,
                showInNav: true,
                permissions: ['LOGGED_IN'],
                title: 'Vouchers shop',
                children: [
                    {
                        path: '/',
                        element: <VendorList />,
                        title: 'Vendor List'
                    },
                    {
                        path: '/:vendorId',
                        element: <VendorDetails />,
                        title: 'Vendor details'
                    }]
            },
            { path: '*', element: <NoMatch /> }
        ];
    }

    public get activeRoutes(): Route[] {
        return this.routes.filter((route) =>
            route.permissions ? this.api.hasPermission(route.permissions) : true
        );
    }

    public get navLinks(): Route[] {
        return this.routes.filter((route) => route.showInNav);
    }
}
